<template>
  <div>
  <v-col
    cols="10"
    offset="1"
  >
    <v-row>
      <v-col
        cols="12"
        sm="2"
        class="d-flex pl-4"
      >
        <Tooltip textToolTip="Statistiques de l'utilisateur.">
          <v-btn
            name="Statistiques établissement"
            icon
            color="green"
            class="mx-2"
          >
            <v-icon
              large
              @click="
                $router.push({
                  path: `/admin/etablissement/statistique/${etablissement.id}`,
                }).catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                    throw error;
                    }
                });
              "
            >mdi-chart-line</v-icon>
          </v-btn>
          </Tooltip>
          
      </v-col>
    </v-row>
    <!--FORM-->
    <v-card elevation="1">
      <v-form
        class="px-5 pt-7 my-4"
        ref="form"
        v-model="valid"
        lazy-validation
      >
      <v-row
          :justify="$vuetify.breakpoint.xs ? 'center' : 'end'"
          class="px-3 py-6"
          >
            <NextButton
            width="auto"
            color="primary"
              text="Rafraichir les données ENT"
              :loading="loadingApi"
              @clicked="refreshData"
              :disable="!etablissement.apikey"
              >
            </NextButton>
      </v-row>
        <!--ligne 0-->
        <v-row>
          <v-col
            cols="25"
            sm="7"
            class="pb-0"
          >
            <v-switch
              v-model="etablissement.ent"
              
              :label="labelActif"
            />
          </v-col>          
        </v-row>
        <!-- ligne 1 -->
        <div v-if="etablissement.ent">
        <v-row>
          <v-col
            cols="10"
            sm="4"
            class="pb-0 my-1"
          >
          <v-select
              v-model="etablissement.entname"
              :items="['Ecole Directe']"
              attach
              chips
              dense
              label="ENT Sélectionné"
              class="pa-1"
              :menu-props="{ top: true, offsetY: true }"
              required
              :loading="!etablissement"
              :rules="[
                () => !!etablissement.entname || 'Ce champ est requis',
              ]"
            ></v-select>
          </v-col>
        </v-row>
        <!--ligne 2-->
        <v-row class="justify-center justify-sm-start">
          <v-col
            cols="10"
            class="pb-0"
            >
            <v-text-field
            v-model="etablissement.apikey"
            label="API Key"
            dense
            filled
              :rules="[
                () => !!etablissement.apikey.length || 'Ce champ est requis',
              ]"
            />
          </v-col>
        </v-row>
      </div>
        <v-row
        :justify="$vuetify.breakpoint.xs ? 'center' : 'end'"
        class="px-3 py-6"
        >
        <NextButton
        @clicked="submit"
        width="280"
        color="primary"
        text="Appliquer les modifications"
        :loading="loading"
            class="mx-a"
          />
        </v-row>
      </v-form>
    </v-card>
  </v-col>
  <!-- popup d'avertissement d'erreur lors de la récupération de données ent -->
  <div class="text-center">
    <v-dialog
      v-model="isError"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Erreur lors de la récupération de données ENT
        </v-card-title>

        <v-card-text>
          <p>L'application a retourné l'erreur suivante :</p> <p>{{this.error}}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="isError = false"
          >
            Compris
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</div>
</template>

<script>
import NextButton from "../Utility/NextButton.vue";
import Tooltip from "../Utility/Tooltip.vue";
import EcoleDirectService from "../../services/EcoleDirectService";
export default {
  props: ['etablissement', 'loading'],
  data() {
    return {
      switchIsHovered: true,
      valid: false,
      loadingApi: false,
      isError: false,
      error: ""
    };
  },
  components: {
    NextButton,
    Tooltip,
  },
  computed: {
    labelActif() {
      if (this.etablissement.ent == true) {
        return "ENT Activé";
      } else {
        return "ENT Désactivé";
      }
    },
    userConnected() {
      return this.$store.state.user;
    },
  },
  methods: {
    /**
     * L'appel au service est remonté dans le composant parent, il est emit par ce composant-ci.
     */
    submit() {
      if ((this.$refs.form.validate() || !this.etablissement.ent) && this.etablissement.uai){
        this.$emit('modifiedEtablissement', this.etablissement);
      }
    },
    hovered() {
      this.switchIsHovered = false;
      this.boutonMove = "pr-1";
    },
    notHovered() {
      this.switchIsHovered = true;
      this.boutonMove = "pr-4";
    },
    refreshData() {
      this.loadingApi = true;
      EcoleDirectService.fetchEntData(this.etablissement.apikey, this.etablissement.uai).then(edData => {
        localStorage.setItem('EdData', JSON.stringify(edData))
        this.$router.push({name: "Mapping"})
      }).catch(() => {
          this.$store.dispatch("setSnackbar", {
            color: 'error',
            text: 'Une erreur est survenue pendant la récupération des données ENT.'
          })
      })
      .finally(() => {
        this.loadingApi = false
      })
    },
  },
};
</script>

<style lang="scss" >
.input__label {
  white-space: nowrap;
}
</style>
