<template>
  <v-card
    class="pb-6 backgroundColor"
    height="100%"
  >
    <v-btn
      name="Retour"
      class="ma-6"
      text
      icon
      color="primary"
      @click="backTo()"
    >
      <v-icon size="60px">mdi-arrow-left-circle-outline</v-icon>
    </v-btn>
    <div class="Welcometitle primary--text titleFontlg">
      Modification du compte : <span class="secondary--text">{{etablissement.id}} </span>
      <span class="primary--text"> {{ etablissement.nom }} </span>
    </div>
    <ModifieEtablissementApiKey :etablissement="etablissement" :loading="loading" @modifiedEtablissement="putEtablissementAPIKey"/>
  </v-card>
</template>

<script>
import ModifieEtablissementApiKey from "../components/EtablissementComponents/ModifieEtablissementApiKey.vue";
import ENTService from "../services/ENTService";

export default {
  components: {
    ModifieEtablissementApiKey
},
  data() {
    return {
      etablissement: {
        id: "",
        uai: "",
        nom: "",
        cp: "",
        ville: "",
        adresse: "",
        pays: "",
        ent: true,
        entname: "",
        apikey: "",
      },
      loading: false,
    };
  },
  mounted() {
    ENTService.getEtablissement(this.$route.params.id)
        .then((response) => this.etablissement = response.data)
        .catch((error) => {
          console.log(error);
        });
  },
  methods: {
    backTo() {
      this.$router.push("/admin/etablissement").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    putEtablissementAPIKey(etablissementAPIKeyData) {
      ENTService.putEtablissement(this.etablissement.id, etablissementAPIKeyData)
          .catch((error) => {
            console.log(error);
          })
    },
  },
};
</script>
 <style scoped>
.subtitle {
  text-align: center;
  margin-bottom: 16px;
  color: #034155;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 24px;
}
.Welcometitle {
  text-align: center;
  margin-bottom: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.titleFontlg {
  font-size: 28px;
}
</style>
