import axios from 'axios'
import AnneService from './AnneeService.js'

async function fetchEntData(apiKey, uai) {
    const fd = new FormData()
    const anneeScolaireInfo = (await AnneService.fetchCurrentAnneeInfo()).data;
    fd.append("data", `{"apiKey": "${apiKey}", "uai": "${uai}", "anneeScolaire": "${parseAnneeScolaire(anneeScolaireInfo)}"}`)
    const result = await axios.post(`https://api.ecoledirecte.com/v3/arbs/utilisateurs.awp?verbe=GET`, fd);
    return result.data.data
}

function parseAnneeScolaire(divaltoAnneeScolaire) {
  return `${divaltoAnneeScolaire.scolaire.start}-${divaltoAnneeScolaire.scolaire.end}`
}

const EcoleDirectService = {
    fetchEntData
}

export default EcoleDirectService
